import Link from "next/link";
import React, { FormEvent, useRef, useState } from "react";
import pushGtmEvent from "../src/utils/ga";
import { Roles, UserType, withSessionSsp } from "../src/utils/session";
import { Button, Card, Input } from "@nextui-org/react";
import { useForm, SubmitHandler, Form } from "react-hook-form";
import { KeyRoundIcon, MailIcon } from "lucide-react";
import { toast } from "react-toastify";

type Inputs = {
  email: string;
  password: string;
};

const texts = {
  fa: {
    email: "ایمیل",
    pass: "پسورد",
  },
  en: {
    email: "Email",
    pass: "Password",
  },
};

const roleToPath = {
  chief: "/report-list",
  manager: "/safar-list",
  admin: "/ongoing-list",
};

export default function Login() {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>();

  return (
    <div className="flex justify-center items-center bg-blue-950 h-full" dir="ltr">
      <Card className="py-12 px-8 md:px-28 md:w-1/2 md:max-w-lg">
        <Form
          className="flex flex-col justify-center items-center gap-2"
          action="/api/login"
          encType="application/json"
          onSuccess={(res) => {
            res.response.json().then((user) => {
              window.location.href = roleToPath[user.role as Roles];
            });
          }}
          onError={() => {
            toast.error('Your Email / Password is Invalid');
          }}
          control={control}
        >
          <h1 className="mb-8 font-bold text-2xl text-center">العتبات للسفر و سیاحیة</h1>
          <Input
            type="email"
            label={texts.en.email}
            // variant="underlined"
            defaultValue=""
            className="max-w-xs"
            size="lg"
            startContent={<MailIcon />}
            {...register("email", {
              required: true,
            })}
            errorMessage={errors.email? 'Enter your email address': ''}
          />
          <Input
            type="password"
            startContent={<KeyRoundIcon />}
            label={texts.en.pass}
            // variant="underlined"
            defaultValue=""
            className="max-w-xs mt-4"
            size="lg"
            errorMessage={errors.password? 'Your password is required': ''}
            {...register("password", { required: true })}
          />
          <div className="flex justify-between items-center w-full mt-8">
            <Button
              color="default"
              isDisabled  
              variant="light"
            >
              Forgot password?
            </Button>
            <Button
              color="primary"
              size="lg"
              isLoading={isSubmitting}
              type="submit"
              className="px-8 bg-blue-900"
            >
              Login
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
}
